import { Only, Section, isVideoMedia } from "src/utils"
import React, { useEffect } from "react"
import { SanityCallToAction, SanityMedia, SanityRichText, ThemeColor } from "../../sanity/types"
import cn from "classnames"
import s from "./CollageModule.module.scss"
import { Container } from "../../layout/container/Container"
import ButtonGroup from "src/components/button-group/ButtonGroup"
import CallToAction from "src/components/call-to-action/CallToAction"
import Media from "src/components/media/Media"
import Icon, { IconType } from "src/components/icon/Icon"
import { RollingText } from "src/components/rolling-text/RollingText"
import RichText from "../../components/rich-text/RichText"
import { BasicImage } from "src/components/image/BasicImage"
import { generateSanityImage, getAspectRatioOMedia } from "src/sanity/image"
import { useInView } from "react-intersection-observer"
import { EmbeddedContent } from "src/components/embedded-content/EmbeddedContent"
import { richTextToString } from "src/utils"
import { Squircle } from "src/components/squircle/Squircle"
import TrustPilot from "src/components/trustpilot/Trustpilot"

export interface BoxProps {
    subtitle?: {
        text: string
    }
    title?: {
        text?: SanityRichText
        size?: string
        lightFontWeight?: boolean
    }
    bodyText?: {
        text?: SanityRichText
        size?: string
    }
    cta?: SanityCallToAction
    secondaryCta?: SanityCallToAction
    media?: {
        media?: SanityMedia
        mobileMedia?: SanityMedia
        position?: "background-fill" | "center" | "bottom" | "bottom-left"
        animation?: string
    }
    overlayMedia?: {
        media?: SanityMedia
        position?: "background-fill" | "center" | "bottom"
        animation?: string
    }
    showTrustpilot?: boolean
    embeddedContent?: Sanity.Schema.InlineCollageModule["box1"]["embeddedContent"]
    usp?: string[]
    disclaimer?: SanityRichText
    boxSettings?: {
        verticallyCenter?: boolean
        horizontallyCenter?: boolean
        narrow?: boolean
        boxed?: boolean
        backgroundColor?: ThemeColor
    }
}

export interface CollageModuleProps {
    box1: BoxProps
    box2: BoxProps
    backgroundMedia?: {
        media?: SanityMedia
        mobileMedia?: SanityMedia
        position?: "background-fill" | "center" | "bottom"
    }
    boxedContainer?: boolean
    border?: boolean
    boxFormat: "square" | "medium" | "small" | "no-minimum"
    swapBoxOrder?: boolean
    onlyFirstBox?: boolean
    backgroundColor?: ThemeColor
    moduleIndex?: number
}

export default function CollageModule({
    box1,
    box2,
    boxFormat = "square",
    backgroundMedia,
    swapBoxOrder,
    onlyFirstBox,
    backgroundColor,
    boxedContainer,
    border,
    moduleIndex,
}: CollageModuleProps): JSX.Element {
    const { ref: containerInViewRef, inView: containerInView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
        rootMargin: "0px",
    })

    // const hasNoMedia = !box1?.media?.media && !box2?.media?.media
    const moduleContrastColor = ["primary-blue", "black", "paas-blue"].includes(backgroundColor)

    const boxes = onlyFirstBox ? [box1] : (swapBoxOrder ? [box2, box1] : [box1, box2])
    const textIsInSecondBox = !richTextToString(boxes[0]?.title?.text) && richTextToString(boxes[1]?.title?.text)

    useEffect(() => {
        if (moduleIndex === 0 && backgroundColor === "black") {
            const frontgroundElement = document.querySelector("#__next")
            if (frontgroundElement instanceof HTMLElement) {
                document.body.style.backgroundColor = "black"
                frontgroundElement.style.backgroundColor = "white"

                return () => {
                    document.body.style.backgroundColor = ""
                    frontgroundElement.style.backgroundColor = ""
                }
            }
        }
    }, [])

    return (
        <Section>
            <div className={cn(s["collage-module__background"], {
                // Don't apply background color if there is a background media. We still use the backgroundColor prop to determine font color via boxContrastColor
                // Background color moves to the container if it's a "boxedContainer"
                [`bg-${backgroundColor}`]: backgroundColor && !backgroundMedia?.media?.type && !boxedContainer,
            })}>
                <Squircle as={Container} cornerRadius={boxedContainer ? 20 : 0} noMargin={boxedContainer !== true} className={cn(s["collage-module"], s[`collage-module--${boxFormat}-format`], {
                    [s["collage-module--border"]]: border,
                    [s["collage-module--boxed"]]: boxedContainer,
                    [`bg-${backgroundColor}`]: backgroundColor && !backgroundMedia?.media?.type && boxedContainer,
                    [s["collage-module--content-right"]]: textIsInSecondBox,
                    [s["collage-module--in-view"]]: containerInView,
                    // [s["collage-module--small-format"]]: hasNoMedia && boxFormat === "medium",
                    [s["collage-module--is-hero"]]: moduleIndex === 0,
                    [s["collage-module--single-box"]]: onlyFirstBox,
                    [s["collage-module--fill-height"]]: ["square", "medium"].includes(boxFormat) && backgroundMedia?.media?.type && backgroundMedia?.position === "background-fill",
                    "text-white": moduleContrastColor,
                })} ref={containerInViewRef}>
                    {/* Background Media */}
                    {backgroundMedia?.media?.type && (
                        <div className={cn(
                            s["collage-module__background-media-wrapper"],
                            backgroundMedia.mobileMedia?.type ? "hide-on-mobile" : null,
                            `position-${backgroundMedia.position}`,
                        )}>
                            {CollageMedia(backgroundMedia.media, backgroundMedia.position, false, true, moduleIndex !== 0)}
                        </div>
                    )}

                    {/* Background Mobile Media */}
                    {backgroundMedia?.mobileMedia?.type && (
                        <div className={cn(
                            s["collage-module__background-media-wrapper"],
                            "hide-on-desktop",
                            `position-${backgroundMedia.position}`,
                        )}>
                            {CollageMedia(backgroundMedia.mobileMedia, backgroundMedia.position, true, true, moduleIndex !== 0, moduleIndex === 0)}
                        </div>
                    )}

                    {boxes.map(({ title, subtitle, bodyText, cta, secondaryCta, media, overlayMedia, showTrustpilot, embeddedContent, usp, disclaimer, boxSettings = {} }: BoxProps, i) => {
                        const boxContrastColor = moduleContrastColor || ["primary-blue", "black", "paas-blue"].includes(boxSettings?.backgroundColor)
                        return (
                            <div className={cn(
                                s["collage-module__box-wrapper"],
                                {
                                    [s["collage-module__box-wrapper--boxed"]]: boxSettings.boxed,
                                    [s["collage-module__box-wrapper--fill-height-on-mobile"]]: title?.text?.length > 0 && ((media?.media?.type || media?.mobileMedia?.type) && media?.position === "background-fill"),
                                    [s[`collage-module__box-wrapper--media-position-${media?.position}`]]: media?.position && (media?.media?.type || media?.mobileMedia?.type),
                                    [s[`collage-module__box-wrapper--has-mobile-media`]]: media?.mobileMedia?.type && media?.position === "background-fill",
                                },
                            )} key={i + "wrapper"}>
                                <Squircle as="div" cornerRadius={boxSettings.boxed ? 20 : 0} className={cn(s["collage-module__box"], onlyFirstBox ? null : s["collage-module__box--" + ((i === 0 && !textIsInSecondBox) || (i === 1 && textIsInSecondBox) ? "top" : "bottom")], onlyFirstBox ? null : s["collage-module__box--" + ((i === 0) ? "left" : "right")], {
                                    [s["collage-module__box--vertically-center"]]: boxSettings.verticallyCenter,
                                    [s["collage-module__box--center"]]: boxSettings.horizontallyCenter,
                                    [s["collage-module__box--narrow"]]: boxSettings.narrow,
                                    [`bg-${boxSettings?.backgroundColor}`]: boxSettings?.backgroundColor,
                                    "text-white": boxContrastColor,
                                })} key={i}>
                                    {/* Subtitle */}
                                    {subtitle?.text && (
                                        <h4 className={cn(s["collage-module__box__sub-title"], "paragraph-100")}>{subtitle.text}</h4>
                                    )}
                                    {/* Text */}
                                    {title?.text && (
                                        <RichText blocks={title.text} tag={moduleIndex === 0 ? "h1" : "h2"} className={cn(s["collage-module__box__title"], title?.size, { "light-font-weight": title.lightFontWeight }, "balance-text")} />
                                    )}
                                    {bodyText?.text && (
                                        <RichText blocks={bodyText.text} className={cn(s["collage-module__box__body-text"], bodyText?.size || "paragraph-200")} />
                                    )}

                                    {/* CTA */}
                                    {(cta?.style || secondaryCta?.style) && (
                                        <ButtonGroup className={cn(s["collage-module__box__cta-group"])}>
                                            <Only if={cta?.style}>
                                                <CallToAction {...cta} className={cn(s["collage-module__box__cta"])} />
                                            </Only>
                                            <Only if={secondaryCta?.style}>
                                                <CallToAction {...secondaryCta} className={cn(s["collage-module__box__cta"])} appearance="secondary" />
                                            </Only>
                                        </ButtonGroup>
                                    )}

                                    {/* Disclaimer, when bottom positioned media */}
                                    {disclaimer && (media?.media?.type && (media?.position === "bottom" || media?.position === "bottom-left")) && (
                                        <RichText blocks={disclaimer} className={s["collage-module__box__disclaimer"]} />
                                    )}

                                    {/* Embedded Content */}
                                    {embeddedContent?.[0]?._type && (
                                        <div className={s["collage-module__box__embedded-content"]}>
                                            <EmbeddedContent object={embeddedContent[0]} />
                                        </div>
                                    )}

                                    {/* Trustpilot widget */}
                                    {showTrustpilot && (
                                        <TrustPilot className={s["collage-module__box__trustpilot"]} />
                                    )}

                                    {/* Media */}
                                    {media?.media?.type && (
                                        <div className={cn(
                                            s["collage-module__box__media-wrapper"],
                                            media?.mobileMedia?.type ? "hide-on-mobile" : null,
                                            s[`collage-module__box__media-wrapper-position-${media.position}`],
                                            s[`collage-module__box__media-wrapper-animation-${media.animation}`],
                                        )}>
                                            {CollageMedia(media.media, media.position, false, false, moduleIndex !== 0)}
                                        </div>
                                    )}

                                    {/* Mobile Media */}
                                    {media?.mobileMedia?.type && (
                                        <div className={cn(
                                            s["collage-module__box__media-wrapper"],
                                            "hide-on-desktop",
                                            s[`collage-module__box__media-wrapper-position-${media.position}`],
                                            s[`collage-module__box__media-wrapper-animation-${media.animation}`],
                                        )}>
                                            {CollageMedia(media.mobileMedia, media.position, true, false, moduleIndex !== 0)}
                                        </div>
                                    )}

                                    {/* Overlay Media */}
                                    {overlayMedia?.media?.type && (
                                        <div className={cn(
                                            s["collage-module__box__overlay-media-wrapper"],
                                            s["collage-module__media-wrapper"],
                                            s[`collage-module__box__media-wrapper-position-${overlayMedia.position}`],
                                            s[`collage-module__box__media-wrapper-animation-${overlayMedia.animation}`],
                                        )}>
                                            <Media
                                                media={overlayMedia.media}
                                                sizes={[
                                                    "(min-width: 768px) 60vw",
                                                    "(min-width: 1024px) 50vw",
                                                    "80vw",
                                                ]}
                                                step={500}
                                                width={1800}
                                                height={Math.ceil(1800 / ((overlayMedia?.media?.height && overlayMedia?.media?.width) ? overlayMedia.media.width / overlayMedia.media.height : 16 / 9))}
                                                alt=""
                                                videoWithoutBorders
                                            />
                                        </div>
                                    )}

                                    {/* Disclaimer */}
                                    {disclaimer && (!media?.media?.type || (media?.position !== "bottom" && media?.position !== "bottom-left")) && (
                                        <RichText blocks={disclaimer} className={s["collage-module__box__disclaimer"]} />
                                    )}

                                    {/* USP */}
                                    {usp && (
                                        <>
                                            <ul className={cn("hide-on-mobile", s["collage-module__box__usp"])}>
                                                {usp.map(uspText => (
                                                    <li key={uspText}><Icon type={IconType.CIRCLED_CHECK} style={{ color: boxContrastColor ? "black" : null }} /> {uspText}</li>
                                                ))}
                                            </ul>
                                            <li className={cn("hide-on-desktop", s["collage-module__box__usp"])}>
                                                <Icon type={IconType.CIRCLED_CHECK} style={{ color: boxContrastColor ? "black" : null }} />
                                                <span className={cn(s["collage-module__box__usp__rolling-text-container"])}>
                                                    <RollingText className="label-100 light-font-weight" animation="roll-up" rollingElements={usp.map(uspText => (
                                                        <span key={uspText}> {uspText}</span>
                                                    ))} />
                                                </span>
                                            </li>

                                        </>
                                    )}

                                </Squircle>
                            </div>
                        )
                    })}
                </Squircle>
            </div>
        </Section>
    )
}

function CollageMedia(media: SanityMedia, position?: string, isMobile = false, isBackgroundMedia = false, lazyLoad = true, isExtraTallMobile = false) {
    if (!media?.type)
        return null

    let ratio = getAspectRatioOMedia(media)
    if (isMobile && position === "background-fill")
        ratio = isExtraTallMobile ? 4 / 9 : 3 / 4
    if (!isMobile && position === "background-fill")
        ratio = isBackgroundMedia ? getAspectRatioOMedia(media) : 1

    return (
        <>
            {/* Image */}
            {media?.type === "image" && (
                <picture className={cn(s["collage-module__box__media__image"])}>
                    <BasicImage
                        sizes={[
                            !isBackgroundMedia ? "(min-width: 768px) 50vw" : null,
                            "100vw",
                        ].filter(Boolean)}
                        {...generateSanityImage({
                            image: media,
                            width: 2000,
                            height: 2000 / ratio,
                            step: 500,
                        })}
                        lazy={lazyLoad}
                        alt={media.originalFilename}
                        // Add aspect ratio to non background-fill images
                        {...(position !== "background-fill" ? { aspectRatio: getAspectRatioOMedia(media) } : {})}
                    />
                </picture>
            )}


            {/* Video */}
            {isVideoMedia(media) && (
                <Media
                    media={media}
                    sizes={[
                        !isBackgroundMedia ? "(min-width: 768px) 50vw" : null,
                        "100vw",
                    ].filter(Boolean)}

                    // If background media (fills two boxes = full screen), otherwise it's 1 box = half screen
                    {...(isBackgroundMedia && !isMobile) ?
                        { width: 1920, height: 1920 / ratio } : { width: 720, height: 720 / ratio }
                    }

                    step={500}
                    alt=""
                    videoWithoutBorders
                />
            )}
        </>
    )
}
