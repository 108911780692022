import React, { useEffect, useState } from "react"
import { Section } from "src/utils"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import s from "./CustomComponent.module.scss"
import Icon, { IconType } from "src/components/icon/Icon"
import { getTranslation } from "src/lib/translations"
import { useSettings } from "src/layout/page/settingsContext"
import smsStyle from "../../components/send-sms/SendSms.module.scss"
import Script from "next/script"
import { IntercomUserCustomAttributes } from "src/lib/intercom"
import InputField from "src/components/input-field/InputField"
import { Button } from "src/components/button/Button"
import { getCallToActionTrackingHandler } from "src/components/call-to-action/CallToAction"
import ButtonGroup from "src/components/button-group/ButtonGroup"
import { useBus } from "react-bus"

export interface CustomComponentProps {
    component: string
    parameters: string
    parentHasContainer?: boolean
}

const customIntegrations = {
    "b2b-inline-signup": function B2BInlineSignup(parameter = "email") {
        const [input, setInput] = useState("")
        const { lang } = useSettings()
        const bus = useBus()
        // const t = getTranslation("downloadOverlay", lang)

        return (
            <div style={{ maxWidth: "550px" }}>
                {parameter === "email" && (
                    <InputField
                        type="email"
                        name="email"
                        // label={t.emailLabel}
                        placeholder={"Indtast din email"}
                        value={input}
                        onChange={(value) => setInput(value)}
                        pill
                        required
                    >
                        <Button pill onClick={function (event) {
                            (getCallToActionTrackingHandler({ event: "downloadOverlay", title: "Kom i gang", linkType: "event", id: "cta", type: "link" }))(event)
                            bus.emit("downloadOverlay", { email: input })
                        }} >Kom i gang</Button>
                    </InputField>
                )}
                {parameter === "cvr" && (
                    <InputField
                        type="number"
                        name="cvr"
                        // label={t.emailLabel}
                        placeholder={"Indtast dit CVR"}
                        value={input}
                        onChange={(value) => setInput(value)}
                        pill
                        required
                    >
                        <Button pill onClick={function (event) {
                            (getCallToActionTrackingHandler({ event: "downloadOverlay", title: "Kom i gang", linkType: "event", id: "cta", type: "link" }))(event)
                            bus.emit("downloadOverlay", { cvr: input })
                        }} >Kom i gang</Button>
                    </InputField>
                )}
                {parameter === "orgType" && (
                    <>
                        <style dangerouslySetInnerHTML={{ __html: ".flex1 {flex: 1}" }} />
                        <label className="label-100">{lang === "dk" ? "Start her – vælg din virksomhedstype:" : "Get started – choose your company type:"}</label>
                        <ButtonGroup>
                            <InputField
                                type="select"
                                name="country"
                                className="flex1"
                                onChange={(value) => setInput(value)}
                            >
                                <option>Anpartsselskab</option>
                                <option>Enkeltmandsvirksomhed</option>
                                <option>Personligt ejet mindre virksomhed</option>
                                <option>Jeg er ved at starte</option>
                            </InputField>
                            <Button pill onClick={function (event) {
                                (getCallToActionTrackingHandler({ event: "downloadOverlay", title: "Kom i gang", linkType: "event", id: "cta", type: "link" }))(event)
                                bus.emit("downloadOverlay")
                            }} >Kom i gang</Button>
                        </ButtonGroup>
                    </>
                )}
            </div>
        )
    },
    "couponCode": function CouponCode(parameter: string) {
        const [couponCodeCopied, setCouponCodeCopied] = useState(false)
        const { lang } = useSettings()
        const t = getTranslation("customComponent", lang)
        const [couponCode, setCouponCode] = useState("")

        useEffect(() => {
            if (parameter.startsWith("selb-referral")) {
                const link1Transaction = `https://lunar.onelink.me/YUrh?af_web_dp=https%3A%2F%2Flunar.app%2Fse%2F%3Futm_source%3Dr300_mar24_1-[USER-ID]&pid=Referral&c=r300_mar24_1&af_click_lookback=30d&af_ad=[USER-ID]`
                const link3Transactions = `https://lunar.onelink.me/YUrh?af_web_dp=https%3A%2F%2Flunar.app%2Fse%2F%3Futm_source%3Dr300_mar24_3-[USER-ID]&pid=Referral&c=r300_mar24_3&af_click_lookback=30d&af_ad=[USER-ID]`
                const link = parameter.endsWith("1-transaction") ? link1Transaction : link3Transactions
                const queryParameters = new URLSearchParams(window.location.search)
                const userId = queryParameters.get("userId")
                setCouponCode(link.replaceAll("[USER-ID]", userId))
            }
        }, [parameter])

        // Create countdown
        const [countdown, setCountdown] = useState("")
        useEffect(() => {
            const interval = setInterval(() => {
                const now = new Date()
                const end = new Date(2023, 11, 31)
                const diff = end.getTime() - now.getTime()
                if (diff < 0) return

                // create string that show days, hours, minutes, seconds left until end date
                const days = Math.floor(diff / (1000 * 60 * 60 * 24))
                const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
                const seconds = Math.floor((diff % (1000 * 60)) / 1000)
                let string = t.countdownString.replace("{days}", days.toString()).replace("{hours}", hours.toString()).replace("{minutes}", minutes.toString()).replace("{seconds}", seconds.toString())
                if (days === 1)
                    string = string.replace("days", "day").replace("dage", "dag")
                if (hours === 1)
                    string = string.replace("hours", "hour").replace("timer", "time")

                setCountdown(string)
            }, 1000)
            return () => clearInterval(interval)
        }, [])

        return (
            <>
                {parameter.startsWith("selb-referral") && (
                    <button style={{ display: "flex", margin: "0 auto" }} className={cn("button", "button--pill", couponCodeCopied ? s["coupon-code--copied"] : null)} onClick={() => { navigator.clipboard.writeText(couponCode); setTimeout(() => setCouponCodeCopied(true), 200); const queue = window?.["_paq"]; queue?.push(["trackEvent", "Click", "Copy Coupon Code"]) }}>
                        {/* <div className={cn(s["coupon-code__inner"])}> */}
                        <Icon style={{ fontSize: "1em", width: "1em", marginRight: "0.5em" }} type={couponCodeCopied ? IconType.CHECK : IconType.COPY} />
                        <span>{couponCodeCopied ? t.couponCopied : t.copyCoupon}</span>
                        {/* </div> */}
                    </button>
                )}
                {!parameter.includes("selb-referral") && (
                    <div style={{ padding: "24px 20px", borderRadius: "24px", background: "var(--fog)" }}>
                        <h2 className="text-center heading-50" style={{ marginBottom: "12px" }}>
                            {t.copyCouponHeader}
                        </h2>
                        <div className={cn(s["coupon-code"], couponCodeCopied ? s["coupon-code--copied"] : null)} onClick={() => { navigator.clipboard.writeText("ShareLunarUnlimited"); setTimeout(() => setCouponCodeCopied(true), 200); const queue = window?.["_paq"]; queue?.push(["trackEvent", "Click", "Copy Coupon Code"]) }}>
                            <div className={cn(s["coupon-code__inner"])}>
                                <div className={cn(s["coupon-code__inner_left"])}>
                                    <div className={cn(s["coupon-code__code"])}>ShareLunarUnlimited</div>
                                    <div className={cn(s["coupon-code__expiration"])}></div>
                                </div>
                                <div className={cn(s["coupon-code__inner_right"])}>
                                    {/* Copy coupon icon */}
                                    <div className={cn(s["coupon-code__copy-icon"])}>
                                        <Icon type={couponCodeCopied ? IconType.CHECK : IconType.COPY} />
                                        <span style={{ lineHeight: "120%" }}>{couponCodeCopied ? t.couponCopied : t.copyCoupon}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <h2 className="text-center heading-50 light-font-weight" style={{ marginTop: "8px" }}>
                            {t.copyCouponSubheader}
                        </h2>
                        {countdown.length > 0 && (
                            <small style={{ textAlign: "center", display: "block", margin: "12px auto", lineHeight: "140%" }}>⏱ {t.expires}<br />{countdown}</small>
                        )}
                    </div>
                )}
            </>
        )
    },
    "bankingServicesHero": () => {
        return (
            <>
                <script async src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
                <div style={{ aspectRatio: 1.96 }} dangerouslySetInnerHTML={{ __html: `<lottie-player src="https://cdn.sanity.io/files/inp43svr/production/70f7a8d6b4098ff031fc54296667f01605620d5c.json" background="transparent" speed="1" loop autoplay></lottie-player>` }}></div>
            </>
        )
    },
    "helpcenterSearch": function HelpcenterSearch() {
        const { lang, segment } = useSettings()
        const t = getTranslation("customComponent", lang)
        const [searchQuery, setSearchQuery] = useState("")
        const sanityLangToIntercomLangMap = {
            "dk": "da",
            "en": "en",
            "se": "sv",
            "no": "nb",
        }
        const intercomLang = sanityLangToIntercomLangMap[lang]
        function search(e: React.FormEvent<HTMLFormElement>) {
            e.preventDefault()
            getCallToActionTrackingHandler({ event: "helpcenter-search", linkType: "event", title: "Helpcenter search" })(e)

            // If no search query is entered, just open the business frontpage
            if (!searchQuery && segment === "business") {
                const langToBusinessPage = {
                    "dk": "https://intercom.help/lunar-business-denmark/da",
                    "en": "https://intercom.help/lunar-business-denmark/en",
                    "se": "https://support.lunar.app/sv/collections/3413925-business",
                    "no": "https://intercom.help/lunar-business-denmark/en",
                }
                window.open(langToBusinessPage[lang], "_blank")
                return
            }
            const searchPage = segment === "business" && ["dk", "en"].includes(lang)
                ? `https://intercom.help/lunar-business-denmark/${intercomLang}/?q=${searchQuery}`
                : `https://support.lunar.app/${intercomLang}/?q=${searchQuery}`

            window.open(searchPage, "_blank")
        }
        return (
            <div style={{ maxWidth: "450px" }} className={s["helpcenter-search"]}>
                <form action="/" onSubmit={search}>
                    <InputField type="text" onChange={(val) => setSearchQuery(val)} name="search" placeholder={t.helpcenterSearchPlaceholder} pill>
                        <Button pill onClick={search}>{t.helpcenterSearchButton}</Button>
                    </InputField>
                </form>
            </div>
        )
    },
    "referAFriend": function ReferAFriend(parameters: CustomComponentProps["parameters"]) {

        // const [searchValue, setSearchValue] = useState("")
        // const [searchResult, setSearchResult] = useState<any>()
        const [inputType = "cvr", redirectPage] = parameters.split("|")
        const [inputValue, setInputValue] = useState("")
        const [isLoading, setIsLoading] = useState(false)
        const [userId, setUserId] = useState("")
        const { lang } = useSettings()
        // get userId from query parameter

        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search)
            setUserId(urlParams.get("userId"))
        }, [])

        async function submitForm() {
            if (inputValue.length < 2) return
            setIsLoading(true)
            async function getRecaptchaToken() {
                return new Promise((resolve) => {
                    window.grecaptcha.ready(async function () {
                        window.grecaptcha.execute("6LfdMsUhAAAAAGicH2UnCNbICPSMUtPBDyb6M0pI", { action: "submit" }).then(async function (recaptchaClientToken) {
                            resolve(recaptchaClientToken)
                        })
                    })
                })
            }
            const recaptchaClientToken = await getRecaptchaToken()

            const customAttributes: IntercomUserCustomAttributes = {
                "Referred by": userId,
                "Preferred language": lang,
            }
            const body = {
                actions: ["createLead"],
                [inputType === "cvr" ? "cvr" : "name"]: inputValue,
                segment: "business",
                referrer: location.href.split("?")[0],
                leadSource: "web-business-referral",
                recaptchaClientToken,
                customAttributes,
            }

            await fetch(`/api/createLead`, {
                body: JSON.stringify(body),
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            setIsLoading(false)

            if (redirectPage)
                window.location.href = redirectPage + "?userId=" + userId
        }

        return (
            <>
                {!userId && (
                    <div style={{ textAlign: "center", fontStyle: "italic", fontWeight: 500 }}>
                        {(lang === "dk" ? "Der opstod en fejl. Prøv venligst at åbne linket igen." : "An error occurred. Please try opening the link again.")}
                    </div>
                )}
                {!!userId && (
                    <>
                        <div className={cn(smsStyle["send-sms__input"])} style={{ "maxWidth": "500px", margin: "0 auto" }}>
                            <input
                                className={cn(smsStyle["send-sms__input__input"])}
                                style={{ padding: "0 15px" }}
                                name={inputType}
                                placeholder={inputType === "cvr" ? (lang === "dk" ? "Din vens CVR-nummer" : "Your friends CVR number") : (lang === "dk" ? "Din vens firmanavn" : "Your friends company name")}
                                onChange={(e) => { setInputValue(e.target.value) }}
                                type={inputType === "cvr" ? "number" : null}
                            />
                            <button type="submit" disabled={isLoading} onClick={submitForm} className={cn("button button--pill")}>{lang === "dk" ? "Henvis" : "Refer"}</button>
                        </div>
                        <Script strategy="lazyOnload" defer={true} src="https://www.google.com/recaptcha/api.js?render=6LfdMsUhAAAAAGicH2UnCNbICPSMUtPBDyb6M0pI" />
                    </>
                )}
            </>
        )
    },
}

export default function CustomComponentModule({ component, parameters, parentHasContainer }: CustomComponentProps): JSX.Element {

    const customIntegration = Object.entries(customIntegrations).find(([componentName]) => component === componentName)

    const ContainerElement = parentHasContainer ? React.Fragment : Container

    return (
        <Section>
            <ContainerElement>
                {/* <Container className={cn(maxWidth === "edge-to-edge" ? s["image-module__container--edge-to-edge"] : null)} noMargin={maxWidth === "edge-to-edge"}> */}
                {!!customIntegration && customIntegration[1](parameters)}
            </ContainerElement>
        </Section>
    )
}



